import React, {useEffect, useState, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {Footer} from './footer';
import {Header} from './header';
import {Sidebar} from './sidebar';
import * as S from './styled';
import {
  createCustomization as createCustomizationApiCall,
  updateCustomization,
  getProductVariant,
} from '../api/helpers/integration';
import {
  canvasToJSON,
  convertCanvasToJSON,
  generateHashCode,
  getFileByDataUrl,
  getParamsWithSpecialKeys,
  getUploadedImageUrl,
  loadAllFonts,
  loadFontsFromLayers,
  objectTypes,
  restoreCanvasFromJSON,
  restoreLayersFromApi,
  syncParams,
  updateResizableText,
} from '../helpers/canvasHelper';
import {ProductPreview} from '../components/productPreview/ProductPreview';
import {withNotification} from '../hocs';
import {showErrorNotification} from '../helpers/errorHelper';
import {Loader} from '../components';
import {
  getLeft,
  getTop,
  setLeftByPercentage,
  setTopByPercentage,
  updateOffsets,
} from '../helpers/positionHelper';
import {syncRefAttrsWithParams} from '../helpers/refsHelper';
import {PatternPreview} from '../components/patternPreview/PatternPreview';
import useWindowDimensions from '../hooks/useWindowDimensions';
import {checkAndFixTemplateSize} from '../helpers/templateHelper';
import {BACKGROUND_TAB} from './sidebar/constants/sidebarMenu';
import {cmToPixels} from '../helpers/unitHelper';

let sessionHashCode;

export const getSessionHashCode = () => {
  return sessionHashCode;
};
export const shortViewCnt = 7;
const transformModes = {scale: 'Scale', size: 'Size'};
const transformMode = transformModes.scale;

export const Layout = withNotification(props => {
  const {showNotification, pid, vid, cartKey, apiConfig} = props;
  const {t} = useTranslation(['layout']);
  const [activeTab, setActiveTab] = useState(null);
  const [customization, setCustomization] = useState();
  const [productVariant, setProductVariant] = useState();
  // const [settings, setSettings] = useState(null);
  // const [objects, setObjects] = useState([]);
  // const [groups, setGroups] = useState([]);
  const [customFonts, setCustomFonts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scale, setScale] = useState(0);
  const [editMode, setEditMode] = useState(true);
  const [selectedObject, setSelectedObject] = useState(null);
  const [shapeChange, setShapeChange] = useState(null);
  const [canvasRotation, setCanvasRotation] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [layers, setLayers] = useState([]);
  const [templateHistory, setTemplateHistory] = useState([]);
  const [templateHistoryStep, setTemplateHistoryStep] = useState(null);
  const skipSaveHistoryStep = useRef(false);
  const settings = useMemo(
    () => layers?.[selectedLayer?.index]?.settings,
    [selectedLayer, layers?.[selectedLayer?.index]?.settings],
  );
  const setSettings = v => {
    setLayers(layers =>
      layers.map((item, index) =>
        index === selectedLayer?.index ? {...item, settings: v} : item,
      ),
    );
  };
  const objects = useMemo(
    () => layers?.[selectedLayer?.index]?.objects,
    [selectedLayer, layers?.[selectedLayer?.index]?.objects],
  );
  const setObjects = v => {
    setLayers(layers =>
      layers.map((item, index) =>
        index === selectedLayer?.index ? {...item, objects: v} : item,
      ),
    );
  };
  const groups = useMemo(
    () => layers?.[selectedLayer?.index]?.groups,
    [selectedLayer, layers?.[selectedLayer?.index]?.groups],
  );
  const personalization = useMemo(
    () => layers?.[selectedLayer?.index]?.personalization,
    [selectedLayer, layers?.[selectedLayer?.index]?.personalization],
  );
  const presetPersonalization = useMemo(
    () => layers?.[selectedLayer?.index]?.presetPersonalization,
    [selectedLayer, layers?.[selectedLayer?.index]?.presetPersonalization],
  );
  const setPersonalization = v => {
    setLayers(layers =>
      layers.map((item, index) =>
        index === selectedLayer?.index ? {...item, personalization: v} : item,
      ),
    );
  };
  const {width: windowWidth, height: windowHeight} = useWindowDimensions();

  const isVertical = useMemo(
    () => windowHeight > windowWidth,
    [windowWidth, windowHeight],
  );

  const sideBarWidth =
    windowWidth / 3 > 500 ? 500 : windowWidth / 3 < 390 ? 390 : windowWidth / 3;
  const template = useMemo(() => productVariant?.template, [productVariant]);
  const canvasPreviewRef = useRef(null);
  const previewWithRulerRef = useRef(null);
  const objectsRefs = useRef([]);
  const previewRef = useRef(null);

  const isPattern = useMemo(
    () => !settings?.fill_style || settings?.fill_style === 'pattern',
    [settings],
  );
  const bgObject = useMemo(
    () => (objects || []).find(i => i?.type === objectTypes.background),
    [objects],
  );

  const isPatternWithoutBgSource =
    isPattern &&
    !(bgObject?.params?.image?.src || bgObject?.params?.fillPatternImage?.src);

  const createCustomization = () => {
    setLoading(true);
    const requestData = {
      external_product_id: pid,
      external_product_variant_id: vid,
      ...(cartKey ? {key: cartKey} : {}),
    };
    createCustomizationApiCall(apiConfig, requestData)
      .then(v => {
        if (v?.data) {
          setCustomization(v?.data);
          sessionHashCode = generateHashCode(new Date().toLocaleString());
        }
      })
      .catch(error => {
        showErrorNotification(error, showNotification);
      })
      .finally(() => setLoading(false));
  };

  const saveCustomization = async () => {
    setLoading(true);
    let previewUrl;
    if (canvasPreviewRef?.current) {
      const previewBlobUrl = settings
        ? canvasPreviewRef.current.toDataURL({pixelRatio: 2})
        : null;
      const previewFile = previewBlobUrl
        ? await getFileByDataUrl(
            previewBlobUrl,
            `preview-${getSessionHashCode()}.png`,
          )
        : null;
      const onUploadError = e => {
        // showErrorNotification(e, showNotification);
        if (e?.response?.status === 410 || e?.response?.status === 404) {
          showNotification({
            message: t('Error creating preview'),
            isError: true,
          });
        }
      };
      previewUrl = previewFile
        ? await getUploadedImageUrl(
            previewFile,
            customization?.id,
            onUploadError,
          )
        : null;
    } else {
      previewUrl = null;
    }

    if (settings && !previewUrl) {
      return true;
    }
    const canvasData = layers.map(i => ({
      ...i,
      ...canvasToJSON(
        i?.settings,
        i?.objects,
        i?.groups,
        i?.customFonts,
        i?.personalization,
        i?.presetPersonalization,
      ),
    }));
    const details = {
      templateId: template?.id,
      ...(canvasData?.length > 1
        ? {
            canvasData,
          }
        : {
            canvasJson: canvasData?.[0],
          }),
      previewUrl,
    };
    const requestData = {
      external_product_id: pid,
      external_product_variant_id: vid,
      key: customization?.key,
      details,
    };
    //console.log('requestData', requestData);
    updateCustomization(apiConfig, requestData)
      .then(v => {
        if (v?.data) {
          setCustomization(v?.data);
          showNotification({message: t('Customization saved')});
          window.parent.postMessage('customizationFinished', '*');
        }
      })
      .catch(error => {
        showErrorNotification(error, showNotification);
      })
      .finally(() => setLoading(false));
  };

  const retrieveProductVariant = () => {
    setLoading(true);
    getProductVariant(apiConfig, pid, vid)
      .then(v => {
        setProductVariant(v?.data);
      })
      .catch(error => {
        showErrorNotification(error, showNotification);
      })
      .finally(() => setLoading(false));
  };

  const parseApiTemplate = template => {
    const templateClone = JSON.parse(JSON.stringify(template));
    setLoading(true);
    Promise.all(
      (templateClone?.layers || []).map(async layer => {
        const layerData = layer?.data;
        await checkAndFixTemplateSize(
          [layerData?.background, ...(layerData?.objects || [])],
          layerData?.settings,
          true,
          true,
        ).then(({objects, settings}) => {
          layerData.background = objects?.[0];
          layerData.settings = settings;
          if (isPattern && !layerData.background?.personalization.length) {
            layerData.background.personalization = ['fillPatternImage'];
          }
          return layer;
        });
      }),
    ).then(() => {
      const layersData = restoreLayersFromApi(templateClone);
      loadFontsFromLayers(layersData).then(() => {
        setLayers(layersData);
        const initialLayerIndex = 0;
        setSelectedLayer({
          id: layersData?.[initialLayerIndex]?.layer_id,
          name: layersData?.[initialLayerIndex]?.layer_name,
          description: layersData?.[initialLayerIndex]?.layer_description,
          order: layersData?.[initialLayerIndex]?.layer_order,
          index: layersData?.[initialLayerIndex]?.index,
        });
        setLoading(false);
      });
    });
  };

  const onUpdatePersonalizationData = (object, paramsData) => {
    const params = {...paramsData};
    const existingPersonalization = personalization.find(
      i => i?.id === object?.id,
    );
    if (existingPersonalization) {
      existingPersonalization.params = {
        ...existingPersonalization?.params,
        ...params,
      };
      setPersonalization([...personalization]);
    } else {
      setPersonalization([
        ...personalization,
        {
          id: object?.id,
          name: object?.name,
          type: object?.type,
          group: object?.params?.group,
          params,
        },
      ]);
    }
  };

  const onUpdateGroup = (params, changedParams) => {
    const groupName = params?.group;
    const groupDetails = groups.find(i => i?.name === groupName);
    const groupItems = objects.filter(i => i?.params.group === groupName);
    groupItems.forEach(i => {
      let updatedParams = [];
      groupDetails.params.forEach(param => {
        updatedParams[param] = params[param];
      });
      i.params = {...i?.params, ...updatedParams};
      syncParams(objectsRefs.current[i?.id], updatedParams);
      onUpdatePersonalizationData(i, getParamsWithSpecialKeys(changedParams));
    });
    setObjects([...objects]);
  };

  const onUpdatePersonalizationParams = (obj, params) => {
    const object = objects.find(i => i?.id === obj?.id) || obj;
    onUpdatePersonalizationData(object, getParamsWithSpecialKeys(params));
    object.params = {...object?.params, ...params};
    if (object.type !== objectTypes.background) {
      updateOffsets(object?.className, object?.params);
    }
    object.key = `id=${object?.id},Math=${Math.random()}`;
    const objectRef = objectsRefs?.current?.[object?.id];
    setObjects([...objects]);
    if (object?.params?.group) {
      onUpdateGroup(object?.params, params);
    }
    if (objectRef) {
      syncParams(objectRef, object.params);
      if (object?.params?.image?.src) {
        object.params.image.onload = () => {
          objectRef.cache();
        };
      } else {
        objectRef.cache();
      }
    }
  };

  const onUpdatePersonalizationParam = (object, param, value) => {
    if (
      param === 'text' &&
      object?.params?.maxLength &&
      value?.length > object.params.maxLength
    ) {
      value = value.substring(0, object.params.maxLength);
    }
    object.params[param] = value;
    if (param === 'text' && object?.params?.height) {
      updateResizableText(object?.params);
    }
    onUpdatePersonalizationParams(object, {[param]: value});
  };

  const restoreStep = step => {
    const json = templateHistory?.[step];
    if (json) {
      const {settings, objects} = restoreCanvasFromJSON(json);
      skipSaveHistoryStep.current = true;
      setSettings(settings);
      setObjects(objects);
      setTemplateHistoryStep(step);
      const currentObject = objects.find(i => i?.id === selectedObject?.id);
      setSelectedObject(currentObject ? {...currentObject} : null);
    }
  };

  const undo = () => {
    const step = templateHistoryStep - 1;
    restoreStep(step);
  };

  const redo = () => {
    const step = templateHistoryStep + 1;
    restoreStep(step);
  };

  useEffect(() => {
    loadAllFonts();
  });

  useEffect(() => {
    if (template) {
      parseApiTemplate(template);
    } else {
      setPersonalization([]);
      setSettings(null);
      setObjects([]);
    }
  }, [template]);

  useEffect(() => {
    if (pid && vid) {
      createCustomization();
      retrieveProductVariant();
    }
  }, [pid, vid]);

  useEffect(() => {
    previewRef.current.restoreFitToPage();
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    if (shapeChange) {
      const obj = objects.find(i => i?.id === shapeChange?.id);
      if (obj) {
        const oRef = objectsRefs.current[obj?.id];
        const top = getTop(obj, settings);
        const left = getLeft(obj, settings);
        obj.params = {...obj.params, ...shapeChange.params};
        obj.key = `id=${obj?.id},Math=${Math.random()}`;
        if (transformMode === transformModes.size) {
          if (!shapeChange.params?.x && !shapeChange.params?.y) {
            updateOffsets(obj.className, obj.params);
            setTopByPercentage(obj, top, settings);
            setLeftByPercentage(obj, left, settings);
          }
        }
        syncRefAttrsWithParams(oRef, obj.params);
        setSelectedObject({...obj});
        setObjects([...objects]);
        //if (shapeChange?.action === 'onDragEnd') {
        oRef.cache();
        //}
      }
    }
  }, [shapeChange]);

  useEffect(() => {
    if (settings && objects?.length) {
      const stringJson = convertCanvasToJSON(settings, objects);
      if (!templateHistory?.length) {
        setTemplateHistory([stringJson]);
        setTemplateHistoryStep(0);
      } /*if (stringJson !== templateHistory[templateHistoryStep])*/ else if (
        !skipSaveHistoryStep.current
      ) {
        const nextStep = templateHistoryStep + 1;
        templateHistory[nextStep] = stringJson;
        setTemplateHistory([...templateHistory]);
        setTemplateHistoryStep(nextStep);
      }
      skipSaveHistoryStep.current = false;
    }
  }, [objects]);

  const patternPreview =
    settings && showPreview ? (
      <PatternPreview
        onClose={() => setShowPreview(false)}
        canvasPreviewRef={canvasPreviewRef}
        previewWithRulerRef={previewWithRulerRef}
        settings={settings}
        scale={scale}
        previewSize={{width: 180, height: 100}}
      />
    ) : null;

  const loader = <Loader />;

  const footer = (
    <Footer
      size={parseInt(scale * 100)}
      setSize={v => setScale(v / 100)}
      onFitToPageClicked={() => previewRef.current.restoreFitToPage()}
      onAddToCartClick={() => saveCustomization()}
      onPreviewClick={() => {
        //setSelectedObject(null);
        setCanvasRotation(0);
        setTimeout(() => setShowPreview(true), 100);
      }}
      editMode={editMode}
      setEditMode={setEditMode}
      canvasRotation={canvasRotation}
      setCanvasRotation={setCanvasRotation}
      isAddToCartDisabled={loading || isPatternWithoutBgSource}
      isVertical={isVertical}
      showNotification={showNotification}
      sideBarWidth={sideBarWidth}
      windowWidth={window.innerWidth}
      onRedo={redo}
      onUndo={undo}
      isUndoActive={templateHistoryStep > 0}
      isRedoActive={templateHistory?.[templateHistoryStep + 1]}
      isShowPreviewButton={isPattern}
    />
  );

  const leftContainer = settings ? (
    <S.ColumSidebar
      $isVertical={isVertical}
      $sideBarWidth={sideBarWidth}
      $isLoading={loading}>
      <Sidebar
        showNotification={showNotification}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        cId={customization?.id}
        total={productVariant?.selling_price}
        objects={objects}
        groups={groups}
        settings={settings}
        onUpdatePersonalizationParam={onUpdatePersonalizationParam}
        onUpdatePersonalizationParams={onUpdatePersonalizationParams}
        selectedObject={selectedObject}
        setSelectedObject={setSelectedObject}
        customFonts={customFonts}
        onDeleteObject={o => {
          setObjects(objects.filter(i => i?.id !== o?.id));
        }}
        onAddObject={(o, callback) => {
          setObjects([...objects, o]);
          callback();
        }}
        onUpdateSettings={v => {
          setSettings({...settings, ...v});
        }}
        setLoading={setLoading}
        isPattern={isPattern}
        presetPersonalization={presetPersonalization}
      />
    </S.ColumSidebar>
  ) : null;

  const rightContainer = windowWidth ? (
    <S.ColumCanvas $isVertical={isVertical} $sideBarWidth={sideBarWidth}>
      <S.EditorArea $isVertical={isVertical}>
        <ProductPreview
          ref={previewRef}
          settings={settings}
          objects={objects}
          objectsRefs={objectsRefs}
          canvasPreviewRef={canvasPreviewRef}
          previewWithRulerRef={previewWithRulerRef}
          scale={scale}
          setScale={setScale}
          editMode={editMode}
          selectedObject={selectedObject}
          setSelectedObject={v => {
            if (v?.id || (!v?.id && activeTab !== BACKGROUND_TAB)) {
              setSelectedObject(v);
            }
          }}
          setShapeChange={setShapeChange}
          canvasRotation={canvasRotation}
          isVertical={isVertical}
          transformMode={transformMode}
        />
      </S.EditorArea>
      {footer}
    </S.ColumCanvas>
  ) : null;

  const layoutH = (
    <S.LayoutWr>
      {loading ? loader : null}
      {leftContainer}
      {rightContainer}
      {patternPreview}
    </S.LayoutWr>
  );

  const layoutV = (
    <S.VerticalLayoutWr>
      {loading ? loader : null}
      {rightContainer}
      {leftContainer}
      {patternPreview}
    </S.VerticalLayoutWr>
  );

  return (
    <S.LayoutRoot>
      <Header
        name={productVariant?.name}
        templateLayers={layers.map(i => ({
          id: i?.layer_id,
          name: i?.layer_name,
          order: i?.layer_order,
          index: i?.index,
        }))}
        selectedLayer={selectedLayer}
        onChangeLayer={v => {
          setSelectedLayer(v);
          setSelectedObject(null);
          setTemplateHistoryStep(0);
          setTemplateHistory([]);
        }}
      />
      {isVertical ? layoutV : layoutH}
    </S.LayoutRoot>
  );
});
