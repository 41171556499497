import styled from 'styled-components';

export const PreviewRoot = styled.div`
  width: ${p => p?.$width}px;
  min-width: ${p => p?.$width}px;
  height: ${p => p?.$height}px;
  margin: 8px;
  overflow: hidden;
`;

export const ImgSide = styled.img`
  width: ${p => p?.$width}px;
  height: ${p => p?.$height}px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
