import React from 'react';
import {useTranslation} from 'react-i18next';
import {ModalWrapper} from '../modalWrapper';
import * as S from './styled';
import {getPreviewDataUrl} from '../../helpers/imageHelper';
import {pixelsToCm} from '../../helpers/unitHelper';

export const PatternPreview = props => {
  const {
    popupRef,
    onClose,
    settings,
    scale,
    canvasPreviewRef,
    previewWithRulerRef,
    previewSize,
  } = props;
  const {t} = useTranslation(['PatternPreview']);
  const buttonsContainer = null;
  const previewBlobUrl = getPreviewDataUrl(
    canvasPreviewRef?.current,
    previewWithRulerRef?.current,
    settings,
    scale,
    1,
  );

  const previewAspectRatio = previewSize.width / previewSize.height;
  const screenWidth = document.documentElement.clientWidth;
  const screenHeight = document.documentElement.clientHeight;
  let containerHeight = 0;
  let containerWidth = 0;
  if (screenWidth > screenHeight) {
    containerHeight = screenHeight * 0.8 - 40;
    containerWidth = containerHeight * previewAspectRatio;
  } else {
    containerWidth = screenWidth * 0.8;
    containerHeight = containerWidth / previewAspectRatio;
  }
  const imageWidthCm = pixelsToCm(settings?.width, settings?.dpi);
  const imageHeightCm = pixelsToCm(settings?.height, settings?.dpi);
  const virtualPixel = containerWidth / previewSize.width;
  const imageWidth = imageWidthCm * virtualPixel;
  const imageHeight = imageHeightCm * virtualPixel;
  const rows = parseInt(containerHeight / imageHeight) + 1;
  const columns = parseInt(containerWidth / imageWidth) + 1;
  const imgContainer = (
    <S.ImgSide
      src={previewBlobUrl}
      alt="Preview"
      $width={imageWidth}
      $height={imageHeight}
    />
  );

  const imagesContainer = Array(rows)
    .fill(0)
    .map((_, i) => (
      <S.Row key={i}>
        {Array(columns)
          .fill(0)
          .map((_, j) => (
            <S.Column key={j}>{imgContainer}</S.Column>
          ))}
      </S.Row>
    ));

  return (
    <ModalWrapper
      popupRef={popupRef}
      onCancelClick={() => onClose()}
      title={t('Pattern Preview')}
      withCross
      isScrolled={false}
      buttonsContainer={buttonsContainer}>
      <S.PreviewRoot $width={containerWidth} $height={containerHeight}>
        {imagesContainer}
      </S.PreviewRoot>
    </ModalWrapper>
  );
};
