import React, {useEffect, useRef} from 'react';
import {cacheObject, classNames, objectTypes} from '../../helpers/canvasHelper';
import KonvaCore from 'konva/lib/Core';
import {getFixedParams} from '../../helpers/renderHelper';
import {CanvasRuler} from '../canvasRuler/CanvasRuler';
import {getRoundValue} from '../../helpers/positionHelper';
import {initImageWithCache} from '../../helpers/imageHelper';
import {CanvasUnitSelector} from '../canvasUnitSelector/CanvasUnitSelector';
import {isFirefox} from '../../helpers/browserHelper';
import Konva from 'react-konva';
import {CanvasZoomControl} from '../canvasZoomControl/CanvasZoomControl';

const CanvasView = props => {
  const {
    canvasPreviewRef,
    previewWithScaleRef,
    width,
    height,
    objects,
    scale,
    objectsRefs,
    withRuler = true,
    rulerWidth,
    visibleArea,
    editMode,
    selectedIds,
    showTransform,
    onObjectClick,
    setShapeChange: setShapeChangeProp,
    canvasRotation,
    dpi = 96,
    onZoomInClick,
    onZoomOutClick,
    transformMode = 'Scale',
  } = props;

  const bgColor = '#f6f6f6';
  const bottomLabelHeight = 12;
  const canvasWidth = width * scale + (withRuler ? rulerWidth * scale : 0);
  const canvasHeight =
    height * scale + (withRuler ? rulerWidth * scale : 0) + bottomLabelHeight;
  const reservedFillWidth = visibleArea.clientWidth * 5;
  const lineHeight = 1 / scale;
  const stageRef = useRef(null);
  const transformRef = useRef(null);
  const objectChangesRef = useRef(null);
  const setShapeChange = v => {
    objectChangesRef.current = v;
  };

  const getCanvasPosX = () => {
    const defX = (visibleArea?.clientWidth - canvasWidth) / 2;
    switch (canvasRotation) {
      case 90:
        return defX + canvasHeight - (canvasHeight - canvasWidth) / 2;
      case 180:
        return defX + canvasWidth;
      case 270:
        return defX - (canvasHeight - canvasWidth) / 2;
      default:
        return defX;
    }
  };

  const getCanvasPosY = () => {
    const defY = (visibleArea?.clientHeight - canvasHeight) / 2;
    switch (canvasRotation) {
      case 90:
        return defY + (canvasHeight - canvasWidth) / 2;
      case 180:
        return defY + canvasHeight;
      case 270:
        return defY + canvasHeight - (canvasHeight - canvasWidth) / 2;
      default:
        return defY;
    }
  };

  useEffect(() => {
    if (selectedIds?.[0]) {
      stageRef.current.container().style.cursor = 'pointer';
    } else {
      stageRef.current.container().style.cursor = 'default';
    }
  }, [selectedIds]);

  useEffect(() => {
    if (objects?.length) {
      objects.forEach(o => {
        const oRef = objectsRefs.current?.[o?.id];
        if (stageRef?.current && oRef) {
          if (o.type !== objectTypes.background && o?.personalization?.length) {
            if (
              !oRef?.eventListeners?.mouseenter?.length &&
              !oRef?.eventListeners?.mouseleave?.length
            ) {
              // oRef.on('mouseenter', function () {
              //   stageRef.current.container().style.cursor = 'pointer';
              // });
              // oRef.on('mouseleave', function () {
              //   stageRef.current.container().style.cursor = 'default';
              // });
            }
            if (isFirefox()) {
              //oRef.cache();
            }
          }
        }
      });
    }
    if (transformRef.current && selectedIds?.length) {
      const allowedToTransformIds = selectedIds.filter(id => {
        const o = objects.find(i => i?.id === id);
        return (
          o?.locked !== true &&
          o?.params?.visible !== false &&
          o?.type !== objectTypes.background
        );
      });
      const allowedObjectsRefs = allowedToTransformIds.map(
        i =>
          /*objectsRefs.current?.[i + 'group'] || */ objectsRefs.current?.[i],
      );
      const objectsRefsNotEmpties = allowedObjectsRefs.filter(i => i);
      transformRef.current.nodes(objectsRefsNotEmpties);
      transformRef.current.getLayer().batchDraw();
    }
  }, [objects, selectedIds]);

  const onDragEnd = (e, id) => {
    setShapeChangeProp({
      id,
      action: 'onDragEnd',
      params: {
        x: e.target.x(),
        y: e.target.y(),
      },
    });
  };

  const onTransform = (e, o) => {
    if (
      o?.className === classNames.textPath ||
      o?.params?.fillPatternImage?.src ||
      selectedIds?.length > 1 ||
      transformMode === 'Scale'
    ) {
      setShapeChange({
        id: o?.id,
        action: 'onTransform',
        params: {
          rotation: getRoundValue(e.target.attrs.rotation),
          scaleX: getRoundValue(e.target.attrs.scaleX),
          scaleY: getRoundValue(e.target.attrs.scaleY),
          x: e.target.x(),
          y: e.target.y(),
        },
      });
    } else if (o?.className === classNames.text) {
      setShapeChange({
        id: o?.id,
        action: 'onTransform',
        params: {
          rotation: getRoundValue(e.target.attrs.rotation),
          scaleX: o?.params?.scaleX || 1,
          scaleY: o?.params?.scaleY || 1,
          ...(o?.params?.width === undefined && o?.params?.height === undefined
            ? {
                fontSize: getRoundValue(
                  (o?.params.fontSize / (o?.params?.scaleY || 1)) *
                    e.target.attrs.scaleY,
                ),
              }
            : {}),
          ...(o?.params?.width !== undefined
            ? {
                width: getRoundValue(
                  (o?.params.width / (o?.params?.scaleX || 1)) *
                    e.target.attrs.scaleX,
                ),
              }
            : {}),
          ...(o?.params.height !== undefined
            ? {
                height: getRoundValue(
                  (o?.params.height / (o?.params?.scaleY || 1)) *
                    e.target.attrs.scaleY,
                ),
              }
            : {}),
        },
      });
    } else {
      setShapeChange({
        id: o?.id,
        action: 'onTransform',
        params: {
          rotation: getRoundValue(e.target.attrs.rotation),
          ...(o?.params?.width !== undefined
            ? {
                width: getRoundValue(
                  (o?.params.width / (o?.params?.scaleX || 1)) *
                    e.target.attrs.scaleX,
                ),
              }
            : {}),
          ...(o?.params.height !== undefined
            ? {
                height: getRoundValue(
                  (o?.params.height / (o?.params?.scaleY || 1)) *
                    e.target.attrs.scaleY,
                ),
              }
            : {}),
          ...(o?.params?.radius !== undefined
            ? {
                radius: getRoundValue(
                  (o?.params.radius / (o?.params?.scaleY || 1)) *
                    e.target.attrs.scaleY,
                ),
              }
            : {}),
        },
      });
    }
  };

  const onMouseAction = () => {
    if (objectChangesRef?.current) {
      setShapeChangeProp(objectChangesRef?.current);
      setShapeChange(null);
    }
  };

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-undef
    const Konva = require('react-konva');

    const objectsListContainer = objects.map(i => {
      if (i?.params?.repeat) {
        let repeatList = [];
        let x = i?.params?.repeatOffsetX || 0;
        let y = i?.params?.repeatOffsetY || 0;
        let line = 0;
        const o = new KonvaCore[i?.className](i?.params);
        const ow = o?.getSize().width * (i?.params?.scaleX || 1);
        const oh = o?.getSize().height * (i?.params?.scaleY || 1);
        const w =
          i?.params?.rotation === 90 || i?.params?.rotation === 270 ? oh : ow;
        const h =
          i?.params?.rotation === 90 || i?.params?.rotation === 270 ? ow : oh;
        const rotateSizeScale = 1.3;
        const maxSide = Math.max(width, height);
        if (i?.params?.repeat === 'repeat-x') {
          while (x < maxSide * rotateSizeScale) {
            repeatList.push(
              <i.className
                key={`key=${i?.key},x=${x},y=${y}`}
                {...i?.params}
                x={x}
                y={y}
              />,
            );
            x += w + (i?.params?.repeatXMargins || 0);
          }
        }
        if (i?.params?.repeat === 'repeat-y') {
          while (y < maxSide * rotateSizeScale) {
            repeatList.push(
              <i.className
                key={`key=${i?.key},x=${x},y=${y}`}
                {...i?.params}
                x={x}
                y={y}
              />,
            );
            y += h + (i?.params?.repeatYMargins || 0);
          }
        }
        if (
          i?.params?.repeat === 'repeat' ||
          i?.params?.repeat === 'repeat-wave' ||
          i?.params?.repeat === 'repeat-wave-vertical'
        ) {
          while (y < maxSide * rotateSizeScale) {
            let row = 0;
            while (x < maxSide * rotateSizeScale) {
              repeatList.push(
                <i.className
                  key={`key=${i?.key},x=${x},y=${y}`}
                  {...getFixedParams(i?.params)}
                  x={x}
                  y={
                    i?.params?.repeat === 'repeat-wave-vertical' && row % 2
                      ? y + h / 2
                      : y
                  }
                />,
              );
              row += 1;
              x += w + (i?.params?.repeatXMargins || 0);
            }
            line += 1;
            x =
              (i?.params?.repeat === 'repeat-wave' && line % 2
                ? (w + (i?.params?.repeatXMargins || 0)) / 2
                : 0) + (i?.params?.repeatOffsetX || 0);
            y += h + (i?.params?.repeatYMargins || 0);
          }
        }
        return (
          <Konva.Group
            ref={ref => {
              if (ref && i?.params) {
                //syncParams(ref, i?.params);
                //cacheObject(ref, i);
              }
              //objectsRefs.current[i?.id] = ref;
            }}
            rotation={i?.params?.repeatRotation || 0}
            key={i?.key}>
            {repeatList}
          </Konva.Group>
        );
      }
      const component = (
        <i.className
          ref={ref => {
            if (
              ref &&
              i?.params &&
              (i?.params?.image || i?.params?.fillPatternImage)
            ) {
              initImageWithCache(i, ref);
            } else if (ref && i?.params && i?.type !== objectTypes.text) {
              cacheObject(ref, i);
            }
            objectsRefs.current[i?.id] = ref;
          }}
          {...getFixedParams(i?.params)}
          key={i?.key}
          draggable={
            editMode &&
            i?.type !== objectTypes.background &&
            i?.personalization?.length /* && !i?.params?.background*/
          }
          onClick={() => {
            if (
              onObjectClick &&
              i.type !== objectTypes.background &&
              i?.personalization?.length
            ) {
              onObjectClick(i);
            } else {
              onObjectClick(null);
            }
          }}
          onDragEnd={e => onDragEnd(e, i?.id)}
          onTransform={e => onTransform(e, i)}
        />
      );

      const backgroundInTextComponent = (
        <Konva.Rect
          // ref={ref => {
          //   objectsRefs.current[i?.id + 'bg'] = ref;
          // }}
          {...i?.params}
          fill={i?.params?.background}
          width={i?.params?.width || objectsRefs.current[i?.id]?.getWidth()}
          height={i?.params?.height || objectsRefs.current[i?.id]?.getHeight()}
        />
      );

      return i?.params?.background ? (
        <Konva.Group
          key={i?.key}
          ref={ref => {
            objectsRefs.current[i?.id + 'group'] = ref;
          }}
          draggable={
            editMode &&
            i?.type !== objectTypes.background &&
            i?.personalization?.length
          }>
          {backgroundInTextComponent}
          {component}
        </Konva.Group>
      ) : (
        component
      );
    });

    const transform = selectedIds?.length ? (
      <Konva.Transformer
        ref={transformRef}
        boundBoxFunc={(oldBox, newBox) => {
          // limit resize
          if (newBox.width < 5 || newBox.height < 5) {
            return oldBox;
          }
          return newBox;
        }}
      />
    ) : null;

    const ruler = (
      <CanvasRuler
        width={width}
        height={height}
        scale={scale}
        rulerWidth={rulerWidth}
        lineWeight={lineHeight}
        clientWidth={visibleArea?.clientWidth}
        canvasWidth={canvasWidth}
        canvasHeight={canvasHeight}
        dpi={dpi}
      />
    );

    const canvasUnitSelector = (
      <CanvasUnitSelector x={visibleArea?.clientWidth - 100} y={15} />
    );

    const canvasZoomControl = (
      <CanvasZoomControl
        x={15}
        y={15}
        onZoomInClick={onZoomInClick}
        onZoomOutClick={onZoomOutClick}
      />
    );

    return (
      <Konva.Stage
        ref={stageRef}
        width={visibleArea?.clientWidth}
        height={visibleArea?.clientHeight}
        onMouseOver={onMouseAction}
        onTouchEnd={onMouseAction}
      >
        <Konva.Layer>
          <Konva.Group
            ref={previewWithScaleRef}
            width={canvasWidth}
            height={canvasHeight}
            scaleX={scale}
            scaleY={scale}
            x={getCanvasPosX()}
            y={getCanvasPosY()}
            clipX={0}
            clipY={0}
            clipWidth={canvasWidth / scale}
            clipHeight={canvasHeight / scale}
            draggable={!editMode}
            rotation={canvasRotation}>
            <Konva.Group
              ref={canvasPreviewRef}
              x={withRuler ? rulerWidth : 0}
              y={withRuler ? rulerWidth : 0}
              clipX={0}
              clipY={0}
              clipWidth={width}
              clipHeight={height}>
              {objectsListContainer}
              {showTransform ? transform : null}
            </Konva.Group>
            {withRuler ? <Konva.Group>{ruler}</Konva.Group> : null}
          </Konva.Group>
          {canvasUnitSelector}
          {canvasZoomControl}
        </Konva.Layer>
      </Konva.Stage>
    );
  }
  return null;
};
export default CanvasView;
